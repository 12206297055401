import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register GSAP ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

function Functional() {
  const { t } = useTranslation();

  // Refs for h1, span, and white boxes
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const boxRefs = useRef([]);

  useEffect(() => {
    // Animate the h1 (title) and span (subtitle)
    gsap.fromTo(
      titleRef.current,
      { opacity: 0, y: 50 }, // Initial state (invisible and moved down)
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top 80%", // Trigger when 80% of the element is visible
          end: "bottom 10%", // End when it's 10% from the bottom
          toggleActions: "play none none reverse", // Reverse the animation when scrolling up
        },
      }
    );

    gsap.fromTo(
      subtitleRef.current,
      { opacity: 0, y: 50 }, // Initial state (invisible and moved down)
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: subtitleRef.current,
          start: "top 80%", // Trigger when 80% of the element is visible
          end: "bottom 10%", // End when it's 10% from the bottom
          toggleActions: "play none none reverse", // Reverse the animation when scrolling up
        },
      }
    );

    // Animate each white box (bg-white)
    gsap.utils.toArray(boxRefs.current).forEach((box, index) => {
      gsap.fromTo(
        box,
        { opacity: 0, y: 50 }, // Initial state (invisible and moved down)
        {
          opacity: 1,
          y: 0,
          duration: 1.2,
          ease: "power3.out",
          scrollTrigger: {
            trigger: box,
            start: "top 80%", // Trigger when 80% of the box is in view
            end: "bottom 10%", // End when it's 10% from the bottom
            toggleActions: "play none none reverse", // Reverse when scrolling back up
          },
        }
      );
    });
  }, []);

  return (
    <section className="pt-[140px] px-[40px] xxl:px-[20px] xml:pt-[100px] lg:pt-[80px] pb-[140px] border-b border-1 border-[#D2D3D4] xml:pb-[100px] lg:pb-[80px]">
     

      <div className="grid grid-flow-row w-full gap-[16px] grid-cols-3 xl:grid-cols-2 md:grid-cols-1">
        {/* White Boxes */}
        <div
          ref={(el) => (boxRefs.current[0] = el)} // Add refs to white boxes
          className="bg-white p-[69px] rounded-[30px] shadow-xl xxl:p-[42px] md:p-[30px]"
        >
          <span className="text-[#CE4800] font-medium block tracking-[-2.4px] text-[60px] leading-[60px] text-left w-full xl:text-[54px]">
            01
          </span>
          

          <div className="grid gap-[30px] pt-[30px] lg:pt-[24px] lg:gap-[18px]">
            <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
              {t("servicesBox1tit")}
            </h3>
            <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium">
              {t("servicesBox1par")}
            </p>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[1] = el)} // Add refs to white boxes
          className="bg-white p-[69px] rounded-[30px] shadow-xl xxl:p-[42px] md:p-[30px]"
        >
          <span className="text-[#CE4800] font-medium block tracking-[-2.4px] text-[60px] leading-[60px] text-left w-full xl:text-[54px]">
            02
          </span>
         

          <div className="grid gap-[30px] pt-[30px] lg:pt-[24px] lg:gap-[18px]">
            <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
              {t("servicesBox2tit")}
            </h3>
            <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium">
              {t("servicesBox2par")}
            </p>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[2] = el)} // Add refs to white boxes
          className="bg-white p-[69px] rounded-[30px] shadow-xl xxl:p-[42px] md:p-[30px]"
        >
          <span className="text-[#CE4800] font-medium block tracking-[-2.4px] text-[60px] leading-[60px] text-left w-full xl:text-[54px]">
            03
          </span>
         

          <div className="grid gap-[30px] pt-[30px] lg:pt-[24px] lg:gap-[18px]">
            <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
              {t("servicesBox3tit")}
            </h3>
            <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium">
              {t("servicesBox3par")}
            </p>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[3] = el)} // Add refs to white boxes
          className="bg-white p-[69px] rounded-[30px] shadow-xl xxl:p-[42px] md:p-[30px]"
        >
          <span className="text-[#CE4800] font-medium block tracking-[-2.4px] text-[60px] leading-[60px] text-left w-full xl:text-[54px]">
            04
          </span>
         

          <div className="grid gap-[30px] pt-[30px] lg:pt-[24px] lg:gap-[18px]">
            <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
              {t("servicesBox4tit")}
            </h3>
            <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium">
              {t("servicesBox4par")}
            </p>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[4] = el)} // Add refs to white boxes
          className="bg-white p-[69px] rounded-[30px] shadow-xl xxl:p-[42px] md:p-[30px]"
        >
          <span className="text-[#CE4800] font-medium block tracking-[-2.4px] text-[60px] leading-[60px] text-left w-full xl:text-[54px]">
            05
          </span>
        

          <div className="grid gap-[30px] pt-[30px] lg:pt-[24px] lg:gap-[18px]">
            <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
              {t("servicesBox5tit")}
            </h3>
            <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium">
              {t("servicesBox5par")}
            </p>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[5] = el)} // Add refs to white boxes
          className="bg-white p-[69px] rounded-[30px] shadow-xl xxl:p-[42px] md:p-[30px]"
        >
          <span className="text-[#CE4800] font-medium block tracking-[-2.4px] text-[60px] leading-[60px] text-left w-full xl:text-[54px]">
            06
          </span>
         

          <div className="grid gap-[30px] pt-[30px] lg:pt-[24px] lg:gap-[18px]">
            <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
              {t("servicesBox6tit")}
            </h3>
            <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium">
              {t("servicesBox6par")}
            </p>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[6] = el)} // Add refs to white boxes
          className="bg-white p-[69px] rounded-[30px] shadow-xl xxl:p-[42px] md:p-[30px]"
        >
          <span className="text-[#CE4800] font-medium block tracking-[-2.4px] text-[60px] leading-[60px] text-left w-full xl:text-[54px]">
            07
          </span>
         

          <div className="grid gap-[30px] pt-[30px] lg:pt-[24px] lg:gap-[18px]">
            <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
              {t("servicesBox7tit")}
            </h3>
            <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium">
              {t("servicesBox7par")}
            </p>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[7] = el)} // Add refs to white boxes
          className="bg-white p-[69px] rounded-[30px] shadow-xl xxl:p-[42px] md:p-[30px]"
        >
          <span className="text-[#CE4800] font-medium block tracking-[-2.4px] text-[60px] leading-[60px] text-left w-full xl:text-[54px]">
            08
          </span>
         

          <div className="grid gap-[30px] pt-[30px] lg:pt-[24px] lg:gap-[18px]">
            <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
              {t("servicesBox8tit")}
            </h3>
            <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium">
              {t("servicesBox8par")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Functional;