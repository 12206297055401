import React, { useEffect, useRef } from "react";
import firstImage from "../assets/plc-training.jpg";
import secondImage from "../assets/dcs-training.jpg";
import tick from "../assets/grey-check.svg";
import bgButton from "../assets/contact-button.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register ScrollTrigger plugin with GSAP
gsap.registerPlugin(ScrollTrigger);

function Training() {
  const { t } = useTranslation();

  // Refs for the h1, span, and the three white boxes
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const boxRefs = useRef([]);

  // GSAP scroll animations for the title and subtitle
  useEffect(() => {
    gsap.fromTo(
      titleRef.current,
      { opacity: 0, y: 50 }, // Start with opacity 0 and 50px below
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top 80%", // Trigger when 80% of the title is in view
          end: "bottom 10%", // End when the title is 10% from the bottom of the viewport
          toggleActions: "play none none reverse", // Reverse when scrolling back up
        },
      }
    );

    gsap.fromTo(
      subtitleRef.current,
      { opacity: 0, y: 50 }, // Start with opacity 0 and 50px below
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: subtitleRef.current,
          start: "top 80%", // Trigger when subtitle is 80% in view
          end: "bottom 10%", // End when subtitle is 10% from the bottom
          toggleActions: "play none none reverse", // Reverse when scrolling up
        },
      }
    );

    // GSAP stagger animation for the three white boxes
    gsap.utils.toArray(boxRefs.current).forEach((box, index) => {
      gsap.fromTo(
        box,
        { opacity: 0, y: 50 }, // Start with opacity 0 and 50px below
        {
          opacity: 1,
          y: 0,
          duration: 1.2,
          ease: "power3.out",
          scrollTrigger: {
            trigger: box,
            start: "top 80%", // Trigger when 80% of the box is in view
            end: "bottom 10%", // End when box is 10% from the bottom
            toggleActions: "play none none reverse", // Reverse when scrolling up
          },
        }
      );
    });
  }, []);

  return (
    <section className="pt-[140px] px-[40px] xxl:px-[20px] xml:pt-[100px] lg:pt-[80px]">
      <div className="flex gap-[148px] items-start max-w-[2000px] w-full mx-auto pb-[80px] xxl:pl-[20px] xxl:gap-[100px] lg:pl-[0px] lg:grid lg:gap-[24px] lg:pb-[60px]">
        {/* Training Header */}
        <div className="flex gap-[15px] items-center mt-[22px] xml:mt-[12px] lg:mt-0">
          <div className="w-[13px] h-[13px] block rounded-[99px] bg-[#CE4800]"></div>
          <span
            ref={subtitleRef} // Attach ref for animation
            className="uppercase text-[18px] font-semibold leading-[21px] tracking-[0px] text-[#CE4800] lg:mt-0"
          >
            {t("training")}
          </span>
        </div>
        <h1
          ref={titleRef} // Attach ref for animation
          className="text-[#1D1D1F] font-light text-[80px] tracking-[-2.7px] leading-[66px] max-w-[800px] xxl:text-[66px] xxl:tracking-[-2.4px] xxl:leading-[63px] xml:text-[51px] xml:leading-[48px] xml:tracking-[-1.2px] md:text-[36px] md:tracking-[-1px] md:leading-[39px]"
        >
          {t("trainingAvb")}
        </h1>
      </div>

      <div className="grid grid-flow-col w-full gap-[16px] grid-cols-3 lg:grid-cols-1 lg:grid-flow-row">
        {/* First White Box */}
        <div
          ref={(el) => (boxRefs.current[0] = el)} // Add reference to the box
          className="bg-white rounded-[30px] p-[60px] grid gap-[80px] content-between shadow-xl xxl:p-[42px] xml:gap-[42px] xml:p-[30px]"
        >
          <img
            src={firstImage}
            alt="PLC Training"
            className="max-w-[260px] w-full h-auto block xml:max-w-[200px]"
          />
          <div className="grid gap-[51px] xxl:gap-[39px] md:gap-[30px]">
            <div className="flex gap-[18px] items-start">
              <img src={tick} alt="tick" />
              <div className="grid gap-[18px]">
                <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
                  PLC Programming
                </h3>
                <p className="text-[18px] leading-[16px] tracking-[-0.6px] text-[#3D414E] font-medium">
                  Level 1, Level 2, Level 3
                </p>
              </div>
            </div>
            <div className="flex gap-[18px] items-start">
              <img src={tick} alt="tick" />
              <div className="grid gap-[18px]">
                <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
                  PLC Maintenance
                </h3>
              </div>
            </div>
          </div>
        </div>

        {/* Second White Box */}
        <div
          ref={(el) => (boxRefs.current[1] = el)} // Add reference to the box
          className="bg-white rounded-[30px] p-[60px] grid gap-[80px] content-between shadow-xl xxl:p-[42px] xml:gap-[24px] xml:p-[30px]"
        >
          <img
            src={secondImage}
            alt="DCS Training"
            className="max-w-[260px] w-full h-auto block xml:max-w-[200px]"
          />
          <div className="grid gap-[51px] xxl:gap-[39px] md:gap-[30px]">
            <div className="flex gap-[18px] items-start">
              <img src={tick} alt="tick" />
              <div className="grid gap-[18px]">
                <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
                  DCS Programming
                </h3>
                <p className="text-[18px] leading-[16px] tracking-[-0.6px] text-[#3D414E] font-medium">
                  Level 1, Level 2, Level 3
                </p>
              </div>
            </div>
            <div className="flex gap-[18px] items-start">
              <img src={tick} alt="tick" />
              <div className="grid gap-[18px]">
                <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
                  DCS Maintenance
                </h3>
              </div>
            </div>
          </div>
        </div>

        {/* Third White Box */}
        <div
          ref={(el) => (boxRefs.current[2] = el)} // Add reference to the box
          className="bg-white rounded-[30px] p-[60px] grid gap-[80px] content-between shadow-xl xxl:p-[42px] xml:gap-[24px] xml:p-[30px]"
        >
          <div className="grid gap-[51px] xxl:gap-[39px] w-full xml:gap-[24px]">
            <h2 className="text-[#1D1D1F] text-[42px] leading-[45px] tracking-[-1px] text-light max-w-[630px] xxl:text-[36px] xxl:leading-[39px] xml:text-[30px] xml:leading-[36px]">
              {t("title-brochures")}
            </h2>
            <div className="flex gap-[16px] items-center xml:grid">
              <Link
                to="/contact"
                className="relative w-[169px] h-[54px] grid items-center justify-center transition-all hover:translate-y-[-6px] px-4"
              >
                <span className="text-white text-[16px] font-semibold text-center tracking-[-0.4px] leading-[16px] relative z-10">
                  {t("contactPage")}
                </span>
                <img
                  src={bgButton}
                  alt="Button Background"
                  className="absolute top-0 left-0 block w-full h-auto shadow-md rounded-[10px]"
                />
              </Link>
              <button className="h-[54px] grid items-center justify-center w-[200px] border-2 border-[#E5E5E5] rounded-[10px] text-[16px] font-bold text-center tracking-[-0.8px] leading-[16px] text-[#1D1D1F] shadow-md transition-all hover:translate-y-[-6px] px-4">
                {t("brochures")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Training;