import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const HamburgerMenu = () => {
  const handleLinkClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const {t} = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null); // Ref to track the menu
  const buttonRef = useRef(null); // Ref to track the hamburger button

  // Toggle the menu open/close
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close the menu if the user clicks outside of it
  const handleClickOutside = (event) => {
    if (
      menuRef.current && !menuRef.current.contains(event.target) &&
      buttonRef.current && !buttonRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  // Add event listener for clicks outside the menu
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative h-[60px] w-[60px] rounded-[6px] items-center justify-center border-2 border-[#f5f5f7] shadow-sm bg-white hidden xl:grid">
      {/* Hamburger Icon (always visible) */}
      <div
        ref={buttonRef} // Attach the ref to the hamburger button
        className={`flex flex-col justify-between items-center w-[27px] h-[26px]  cursor-pointer z-[99] space-y-2`}
        onClick={toggleMenu}
      >
        <span
          className={`block w-full h-2 bg-[#CE4800] transition-transform duration-300 ease-in-out ${
            isOpen ? 'rotate-45 translate-y-[21px]' : ''
          }`}
        ></span>
        <span
          className={`block w-full h-2 bg-[#CE4800] transition-opacity duration-300 ease-in-out ${
            isOpen ? 'opacity-0' : 'opacity-100'
          }`}
        ></span>
        <span
          className={`block w-full h-2 bg-[#CE4800] transition-transform duration-300 ease-in-out ${
            isOpen ? '-rotate-45 translate-y-[-2px]' : ''
          }`}
        ></span>
      </div>

      {/* Sidebar Menu (right side) */}
      <div
        ref={menuRef} // Attach the ref to the sidebar
        className={`fixed top-0 right-0 w-[70%] h-full hamburger-bg text-white transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <ul className="grid items-center content-center text-right justify-center justify-items-end  gap-[33px] h-full">
        <Link
                to={"/"}
                onClick={handleLinkClick}
                className="text-[21px] font-medium text-right underline text-white tracking-[-0.6px] leading-[18px] inline-block w-fit transition-all hover:translate-y-[-6px] cursor-pointer xxl:leading-[21px] xxl:tracking-[-1px] xxl:text-[21px]"

              >
                {t('homePage')}
              </Link>
              <Link
                to={"/about"}
                onClick={handleLinkClick}
                className="text-[21px] font-medium text-right underline text-white tracking-[-0.6px] leading-[18px] inline-block w-fit transition-all hover:translate-y-[-6px] cursor-pointer xxl:leading-[21px] xxl:tracking-[-1px] xxl:text-[21px]"
              >
                {t('aboutPage')}
              </Link>
              <Link
                to={"/services"}
                onClick={handleLinkClick}
                className="text-[21px] font-medium text-right underline text-white tracking-[-0.6px] leading-[18px] inline-block w-fit transition-all hover:translate-y-[-6px] cursor-pointer xxl:leading-[21px] xxl:tracking-[-1px] xxl:text-[21px]"
              >
               {t('servicesPage')}
              </Link>
              <Link
                to={"/contact"}
                onClick={handleLinkClick}
                className="text-[21px] font-medium text-right underline text-white tracking-[-0.6px] leading-[18px] inline-block w-fit transition-all hover:translate-y-[-6px] cursor-pointer xxl:leading-[21px] xxl:tracking-[-1px] xxl:text-[21px]"
              >
                {t('contactPage')}
              </Link>
        </ul>
      </div>
    </div>
  );
};

export default HamburgerMenu;