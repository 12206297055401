import React, { useEffect, useRef } from "react";
import Img1 from "../assets/img-t-1.svg";
import Img2 from "../assets/img-t-2.svg";
import Img3 from "../assets/img-t-3.svg";
import Img4 from "../assets/img-t-4.svg";
import Img7 from "../assets/img-t-7.svg";
import Img6 from "../assets/img-t-6.svg";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Expertise = () => {
  gsap.registerPlugin(ScrollTrigger);
  const { t } = useTranslation();

  // Refs for h1, span, and white boxes
  const titleRef = useRef(null);
  const boxRefs = useRef([]);

  useEffect(() => {
    // Animate the h1 (title) and span (subtitle)
    gsap.fromTo(
      titleRef.current,
      { opacity: 0, y: 50 }, // Initial state (invisible and moved down)
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top 80%", // Trigger when 80% of the element is visible
          end: "bottom 10%", // End when it's 10% from the bottom
          toggleActions: "play none none reverse", // Reverse the animation when scrolling up
        },
      }
    );

    // Animate each white box (bg-white)
    gsap.utils.toArray(boxRefs.current).forEach((box, index) => {
      gsap.fromTo(
        box,
        { opacity: 0, y: 50 }, // Initial state (invisible and moved down)
        {
          opacity: 1,
          y: 0,
          duration: 1.2,
          ease: "power3.out",
          scrollTrigger: {
            trigger: box,
            start: "top 80%", // Trigger when 80% of the box is in view
            end: "bottom 10%", // End when it's 10% from the bottom
            toggleActions: "play none none reverse", // Reverse when scrolling back up
          },
        }
      );
    });
  }, []);

  return (
    <>
      <section className="pt-[140px] px-[40px] xxl:px-[20px] xml:pt-[100px] lg:pt-[80px] pb-[80px] xml:pb-[60px] lg:pb-[42px] relative max-w-[2000px] w-full mx-auto">
        <div className="flex gap-[148px] items-start max-w-[2000px] w-full mx-auto xxl:pl-[20px] xxl:gap-[100px] lg:grid lg:pl-0 lg:gap-[24px]">
          <div className="grid gap-[60px] xxl:gap-[42px] xl:gap-[36px] md:gap-[30px]">
            <h1
              className="text-[#1D1D1F] font-light text-[80px] tracking-[-2.7px] leading-[66px] max-w-[800px] xxl:text-[66px] xxl:tracking-[-2.4px] xxl:leading-[63px] xml:text-[51px] xml:leading-[48px] xml:tracking-[-1.2px] md:text-[36px] md:tracking-[-1px] md:leading-[39px]"
              ref={titleRef}
            >
              {t("expertiseTit")}
            </h1>
          </div>
        </div>
      </section>

      <div className="grid gap-[20px] px-[40px] xxl:px-[20px]">
        <div
          className="bg-white boxicc p-[60px] xxl:p-[51px] xl:p-[42px] md:p-[30px] rounded-[30px] md:rounded-[21px] w-full flex items-center justify-between shadow-xl"
          ref={(el) => (boxRefs.current[0] = el)}
        >
          <div className="flex gap-[42px] items-center lg:grid lg:gap-[24px]">
            <img
              src={Img1}
              alt="PLC Training"
              className="max-w-[260px] w-full h-auto block xml:max-w-[200px]"
            />
            <div className="grid gap-[24px] justify-start">
              <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
                {t("expertise1tit")}
              </h3>
              <div className="grid gap-[21px] max-w-[900px] xxl:max-w-[700px] w-full">
                <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium xxl:text-[16px] xxl:leading-[21px] xxl:tracking-[-0.4px]">
                  {t("expertise1par1")}
                </p>
              </div>
            </div>
          </div>

          <span className="text-[#F5F5F7] text-[100px] leading-[100px] tracking-[-3px] pr-[80px] xml:hidden">
            01
          </span>
        </div>

        <div
          className="bg-white boxicc p-[60px] xxl:p-[51px] xl:p-[42px] md:p-[30px] rounded-[30px] md:rounded-[21px] w-full flex items-center justify-between shadow-xl"
          ref={(el) => (boxRefs.current[1] = el)}
        >
          <div className="flex gap-[42px] items-center lg:grid lg:gap-[24px]">
            <img
              src={Img2}
              alt="PLC Training"
              className="max-w-[260px] w-full h-auto block xml:max-w-[200px]"
            />
            <div className="grid gap-[24px] justify-start">
              <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
                {t("expertise2tit")}
              </h3>
              <div className="grid gap-[21px] max-w-[900px] xxl:max-w-[700px] w-full">
                <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium xxl:text-[16px] xxl:leading-[21px] xxl:tracking-[-0.4px]">
                  {t("expertise2par1")}
                </p>
              </div>
            </div>
          </div>

          <span className="text-[#F5F5F7] text-[100px] leading-[100px] tracking-[-3px] pr-[80px] xml:hidden">
            02
          </span>
        </div>

        <div
          className="bg-white boxicc p-[60px] xxl:p-[51px] xl:p-[42px] md:p-[30px] rounded-[30px] md:rounded-[21px] w-full flex items-center justify-between shadow-xl"
          ref={(el) => (boxRefs.current[2] = el)}
        >
          <div className="flex gap-[42px] items-center lg:grid lg:gap-[24px]">
            <img
              src={Img4}
              alt="PLC Training"
              className="max-w-[260px] w-full h-auto block xml:max-w-[200px]"
            />
            <div className="grid gap-[24px] justify-start">
              <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
                {t("expertise3tit")}
              </h3>
              <div className="grid gap-[21px] max-w-[900px] xxl:max-w-[700px] w-full">
                <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium xxl:text-[16px] xxl:leading-[21px] xxl:tracking-[-0.4px]">
                  {t("expertise3par1")}
                </p>
              </div>
            </div>
          </div>

          <span className="text-[#F5F5F7] text-[100px] leading-[100px] tracking-[-3px] pr-[80px] xml:hidden">
            03
          </span>
        </div>

        <div
          className="bg-white boxicc p-[60px] xxl:p-[51px] xl:p-[42px] md:p-[30px] rounded-[30px] md:rounded-[21px] w-full flex items-center justify-between shadow-xl"
          ref={(el) => (boxRefs.current[3] = el)}
        >
          <div className="flex gap-[42px] items-center lg:grid lg:gap-[24px]">
            <img
              src={Img3}
              alt="PLC Training"
              className="max-w-[260px] w-full h-auto block xml:max-w-[200px]"
            />
            <div className="grid gap-[24px] justify-start">
              <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
                {t("expertise4tit")}
              </h3>
              <div className="grid gap-[21px] max-w-[900px] xxl:max-w-[700px] w-full">
                <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium xxl:text-[16px] xxl:leading-[21px] xxl:tracking-[-0.4px]">
                  {t("expertise4par1")}
                </p>
              </div>
            </div>
          </div>

          <span className="text-[#F5F5F7] text-[100px] leading-[100px] tracking-[-3px] pr-[80px] xml:hidden">
            04
          </span>
        </div>

        <div
          className="bg-white boxicc p-[60px] xxl:p-[51px] xl:p-[42px] md:p-[30px] rounded-[30px] md:rounded-[21px] w-full flex items-center justify-between shadow-xl"
          ref={(el) => (boxRefs.current[4] = el)}
        >
          <div className="flex gap-[42px] items-center lg:grid lg:gap-[24px]">
            <img
              src={Img6}
              alt="PLC Training"
              className="max-w-[260px] w-full h-auto block xml:max-w-[200px]"
            />
            <div className="grid gap-[24px] justify-start">
              <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
                {t("expertise5tit")}
              </h3>
              <div className="grid gap-[21px] max-w-[900px] xxl:max-w-[700px] w-full">
                <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium xxl:text-[16px] xxl:leading-[21px] xxl:tracking-[-0.4px]">
                  {t("expertise5par1")}
                </p>
                <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium xxl:text-[16px] xxl:leading-[21px] xxl:tracking-[-0.4px]">
                  {t("expertise5par2")}
                </p>

                <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium xxl:text-[16px] xxl:leading-[21px] xxl:tracking-[-0.4px]">
                  {t("expertise5par3")}
                </p>
              </div>
            </div>
          </div>

          <span className="text-[#F5F5F7] text-[100px] leading-[100px] tracking-[-3px] pr-[80px] xml:hidden">
            05
          </span>
        </div>

        <div
          className="bg-white boxicc p-[60px] xxl:p-[51px] xl:p-[42px] md:p-[30px] rounded-[30px] md:rounded-[21px] w-full flex items-center justify-between shadow-xl"
          ref={(el) => (boxRefs.current[5] = el)}
        >
          <div className="flex gap-[42px] items-center lg:grid lg:gap-[24px]">
            <img
              src={Img7}
              alt="PLC Training"
              className="max-w-[260px] w-full h-auto block xml:max-w-[200px]"
            />
            <div className="grid gap-[24px] justify-start">
              <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
                {t("expertise6tit")}
              </h3>
              <div className="grid gap-[21px] max-w-[900px] xxl:max-w-[700px] w-full">
                <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium xxl:text-[16px] xxl:leading-[21px] xxl:tracking-[-0.4px]">
                  {t("expertise6par1")}
                </p>
                <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium xxl:text-[16px] xxl:leading-[21px] xxl:tracking-[-0.4px]">
                  {t("expertise6par2")}
                </p>

                <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium xxl:text-[16px] xxl:leading-[21px] xxl:tracking-[-0.4px]">
                  {t("expertise6par3")}
                </p>
              </div>
            </div>
          </div>

          <span className="text-[#F5F5F7] text-[100px] leading-[100px] tracking-[-3px] pr-[80px] xml:hidden">
            06
          </span>
        </div>
      </div>
    </>
  );
};

export default Expertise;
