// LanguageSelector.js
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../assets/select-arrow.svg";

import Uk from "../assets/uk.svg";
import Ger from "../assets/ger.svg";
import Rom from "../assets/rom.svg";

const LanguageSelector = () => {
  // State to track whether the dropdown is open
  const [isOpen, setIsOpen] = useState(false);

  // Access the i18n instance to change the language
  const { i18n } = useTranslation();

  // Define language names for the button text
  const languageNames = {
    en: "English",
    de: "Deutsch",
    ro: "Română",
  };

  // Reference for the dropdown menu
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null); // Reference for the button to handle outside clicks

  // Handle language change
  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setIsOpen(false); // Close the dropdown after selecting a language
  };

  // Handle clicking outside the dropdown to close it
  useEffect(() => {
    // Function to detect click outside the dropdown or button
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) && // Outside dropdown
        buttonRef.current &&
        !buttonRef.current.contains(event.target) // Outside button
      ) {
        setIsOpen(false); // Close the dropdown if clicked outside
      }
    };

    // Add event listener for clicks outside the dropdown
    document.addEventListener("click", handleClickOutside);

    // Cleanup event listener when the component is unmounted
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      {/* Button to toggle the dropdown */}
      <button
        ref={buttonRef} // Attach ref to the button
        onClick={() => setIsOpen(!isOpen)} // Toggle dropdown open/close
        className="px-4 py-2 bg-white rounded-[6px] text-[#1D1D1F] focus:outline-none flex justify-between items-center min-w-[160px] font-semibold tracking-[-0.4px] border-[#F5F5F7] border-2 shadow-sm h-[60px] hover:text-[#CE4800]"
      >
        {languageNames[i18n.language] || "Select Language"}{" "}
        {/* Display current language name */}
        <img src={Icon} alt="img" className="w-[24px] h-auto block" />
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div
          ref={dropdownRef} // Attach ref to dropdown container
          className="absolute right-0 mt-2 w-40 bg-white border rounded-md shadow-lg z-50 "
        >
          <ul className="py-2">
            <li>
              <button
                onClick={() => handleLanguageChange("ro")}
                className="px-4 py-2 text-[#1D1D1F] hover:bg-gray-100 w-full text-left font-semibold tracking-[-0.6px] flex items-center gap-[12px]"
              >
                <img src={Rom} alt="img" />
                Română
              </button>
            </li>

            <li>
              <button
                onClick={() => handleLanguageChange("de")}
                className="px-4 py-2 text-[#1D1D1F] hover:bg-gray-100 w-full text-left font-semibold tracking-[-0.6px] flex items-center gap-[12px]"
              >
                <img src={Ger} alt="img" />
                Deutsch
              </button>
            </li>

            <li>
              <button
                onClick={() => handleLanguageChange("en")}
                className="px-4 py-2 text-[#1D1D1F] hover:bg-gray-100 w-full text-left font-semibold tracking-[-0.6px] flex items-center gap-[12px]"
              >
                {" "}
                <img src={Uk} alt="img" />
                English
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
