import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../assets/icon-news.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register ScrollTrigger plugin with GSAP
gsap.registerPlugin(ScrollTrigger);

function Newsletter() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // For now, just log the email to console
    if (email) {
      setMessage(t('thankyou'));
    } else {
      setMessage(t('thankyouElse'));
    }
  };

  const { t } = useTranslation();

  // Ref for the h1 title to animate
  const titleRef = useRef(null);

  // GSAP scroll animations for the title
  useEffect(() => {
    gsap.fromTo(
      titleRef.current,
      { opacity: 0, y: 50 }, // Starting position: transparent and 50px below
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top 80%", // Trigger when top of element hits 80% of viewport height
          end: "bottom 10%", // End when bottom of element is 10% from the viewport
          toggleActions: "play none none reverse", // Reverse the animation when scrolling up
        },
      }
    );
  }, []);

  return (
    <div className="max-w-[2000px] w-full m-auto pt-[140px] pl-[40px] pr-[200px] xxl:pr-[40px] flex justify-between items-start xml:grid xml:gap-[70px] xml:w-full xml:justify-stretch xml:pt-[120px] msm:gap-[51px] msm:pt-[100px] msm:pl-[20px] msm:pr-[20px]">
      <h1
        ref={titleRef} // Attach the ref to the h1 for GSAP animation
        className="text-[#1D1D1F] font-light text-[80px] tracking-[-2.7px] leading-[66px] max-w-[455px] xxl:text-[66px] xxl:tracking-[-2.4px] xxl:leading-[63px] xml:text-[51px] xml:leading-[48px] xml:tracking-[-1.2px] md:text-[36px] md:tracking-[-1px] md:leading-[39px]"
      >
        {t("newsletterH")}
      </h1>
      <div className="grid pb-[140px] w-full max-w-[900px] xxl:max-w-[750px] xml:pb-[120px] msm:pb-[100px]">
        <form
          onSubmit={handleSubmit}
          className="flex gap-[0px] w-full justify-end box-border msm:grid msm:justify-stretch msm:gap-[60px]"
        >
          <div className="h-fit w-full ">
            <label
              htmlFor="email"
              className="text-[24px] text-[#1D1D1F] font-normal tracking-[-1px] xxl:text-[21px] xxl:tracking-[-0.7px]"
            >
              {t('yourEmail')}
            </label>
            <input
              className="bg-transparent text-[16px] tracking-[-0.4px] h-[48px] w-full border-b-[2px] border-black outline-none msm:border-b-[1px]"
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder=""
              required
            />
          </div>
          <button
            type="submit"
            className="flex items-start justify-between gap-[42px] text-[#1D1D1F] text-[24px] font-medium tracking-[-1.4px] border-b-2 msm:border-b-[1px] border-black w-full max-w-[230px] xxl:text-[21px] xxl:tracking-[-1px] msm:h-[70px] msm:w-full msm:max-w-[100%]"
          >
            {t('subscribe')}
            <img src={Icon} alt="img" className="translate-y-[-9px]" />
          </button>
        </form>
        {message && <p className="text-[#3D414E] font-normal text-[21px] leading-[30px] tracking-[-1px] pt-[24px] xxl:text-[18px] xxl:tracking-[-0.6px] xxl:leading-[24px]">{message}</p>}
        <p className="pt-[140px] max-w-[430px] w-full text-[#3D414E] font-normal text-[21px] leading-[30px] tracking-[-1px] xxl:pt-[90px] xxl:text-[18px] xxl:tracking-[-0.6px] xxl:leading-[24px] msm:pt-[60px]">
          {t('newsPolicy')}
        </p>
      </div>
    </div>
  );
}

export default Newsletter;