import Img1 from "../assets/pharma.jpg";
import Img2 from "../assets/beverage.jpg";
import Img3 from "../assets/chemical.jpg";
import Img4 from "../assets/oil.jpg";
import Img5 from "../assets/marine.jpg";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { useTranslation } from "react-i18next";

// Register GSAP ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const Industries = () => {
  const { t } = useTranslation();

  // Refs for h1, span, and white boxes
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const boxRefs = useRef([]);

  useEffect(() => {
    // Animate the h1 (title) and span (subtitle)
    gsap.fromTo(
      titleRef.current,
      { opacity: 0, y: 50 }, // Initial state (invisible and moved down)
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top 80%", // Trigger when 80% of the element is visible
          end: "bottom 10%", // End when it's 10% from the bottom
          toggleActions: "play none none reverse", // Reverse the animation when scrolling up
        },
      }
    );

    gsap.fromTo(
      subtitleRef.current,
      { opacity: 0, y: 50 }, // Initial state (invisible and moved down)
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: subtitleRef.current,
          start: "top 80%", // Trigger when 80% of the element is visible
          end: "bottom 10%", // End when it's 10% from the bottom
          toggleActions: "play none none reverse", // Reverse the animation when scrolling up
        },
      }
    );

    // Animate each white box (bg-white)
    gsap.utils.toArray(boxRefs.current).forEach((box, index) => {
      gsap.fromTo(
        box,
        { opacity: 0, y: 50 }, // Initial state (invisible and moved down)
        {
          opacity: 1,
          y: 0,
          duration: 1.2,
          ease: "power3.out",
          scrollTrigger: {
            trigger: box,
            start: "top 80%", // Trigger when 80% of the box is in view
            end: "bottom 10%", // End when it's 10% from the bottom
            toggleActions: "play none none reverse", // Reverse when scrolling back up
          },
        }
      );
    });
  }, []);

  return (
    <section className="pt-[140px] pb-[140px] px-[40px] xxl:px-[20px] xml:pt-[100px] lg:pt-[80px] border-b border-1 border-[#D2D3D4] xml:pb-[100px] lg:pb-[80px]">
      <div className="flex gap-[148px] items-start max-w-[2000px] w-full mx-auto pb-[80px] xxl:pl-[20px] xxl:gap-[100px] lg:pl-[0px] lg:grid lg:gap-[24px] lg:pb-[60px]">
        {/* Industries Header */}
        <div className="flex gap-[15px] items-center mt-[22px] xml:mt-[12px] lg:mt-0">
          <div className="w-[13px] h-[13px] block rounded-[99px] bg-[#CE4800]"></div>
          <span
            ref={subtitleRef}
            className="uppercase text-[18px] font-semibold leading-[21px] tracking-[0px] text-[#CE4800] lg:mt-0"
          >
           {t("marketsSpan")}
          </span>
        </div>
        <h1
          ref={titleRef}
          className="text-[#1D1D1F] font-light text-[80px] tracking-[-2.7px] leading-[66px] max-w-[800px] xxl:text-[66px] xxl:tracking-[-2.4px] xxl:leading-[63px] xml:text-[51px] xml:leading-[48px] xml:tracking-[-1.2px] md:text-[36px] md:tracking-[-1px] md:leading-[39px]"
        >
          {t("marketsTit")}
        </h1>
      </div>

      <div className="grid gap-[16px] grid-cols-5 w-full xml:grid-cols-3 lg:grid-cols-2 md:grid-cols-1">
        <div
          ref={(el) => (boxRefs.current[0] = el)}
          className="boxic bg-white rounded-[27px] p-[14px] flex flex-col gap-[9px] shadow-xl"
        >
          <img
            src={Img1}
            alt="PLC Training"
            className="w-full h-auto block rounded-[36px]"
          />

          <div className="p-[30px] bg-[#F2F2F2] rounded-[36px] flex h-full flex-col gap-[12px] justify-start xxl:p-[21px]">
            <h3 className="text-[#1D1D1F] text-[20px] font-bold tracking-[-1px] leading-[24px]">
            {t("pharmaTit")}
            </h3>
            <div className="grid gap-[15px]">
              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("pharma1")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("pharma2")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("pharma3")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("pharma4")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("pharma5")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("pharma6")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("pharma7")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[1] = el)}
          className="boxic bg-white rounded-[27px] p-[14px] flex flex-col gap-[9px] shadow-xl"
        >
          <img
            src={Img2}
            alt="PLC Training"
            className="w-full h-auto block rounded-[36px]"
          />

          <div className="p-[30px] bg-[#F2F2F2] rounded-[36px] flex h-full flex-col gap-[12px] justify-start xxl:p-[21px]">
            <h3 className="text-[#1D1D1F] text-[20px] font-bold tracking-[-1px] leading-[24px]">
            {t("beverageTit")}
            </h3>
            <div className="grid gap-[15px]">
              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("beverage1")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("beverage2")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("beverage3")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("beverage4")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("beverage5")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("beverage6")}
                </p>
              </div>

             
            </div>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[2] = el)}
          className="boxic bg-white rounded-[27px] p-[14px] flex flex-col gap-[9px] shadow-xl"
        >
          <img
            src={Img3}
            alt="PLC Training"
            className="w-full h-auto block rounded-[36px]"
          />

          <div className="p-[30px] bg-[#F2F2F2] rounded-[36px] flex h-full flex-col gap-[12px] justify-start xxl:p-[21px]">
            <h3 className="text-[#1D1D1F] text-[20px] font-bold tracking-[-1px] leading-[24px]">
            {t("chemicalTit")}
            </h3>
            <div className="grid gap-[15px]">

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("chemical1")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("chemical2")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("chemical3")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("chemical4")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("chemical5")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[3] = el)}
          className="boxic bg-white rounded-[27px] p-[14px] flex flex-col gap-[9px] shadow-xl"
        >
          <img
            src={Img4}
            alt="PLC Training"
            className="w-full h-auto block rounded-[36px]"
          />

          <div className="p-[30px] bg-[#F2F2F2] rounded-[36px] flex h-full flex-col gap-[12px] justify-start xxl:p-[21px]">
            <h3 className="text-[#1D1D1F] text-[20px] font-bold tracking-[-1px] leading-[24px]">
            {t("oilTit")}
            </h3>
            <div className="grid gap-[15px]">
              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("oil1")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("oil2")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("oil3")}
                </p>
              </div>

          
            </div>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[4] = el)}
          className="boxic bg-white rounded-[27px] p-[14px] flex flex-col gap-[9px] shadow-xl"
        >
          <img
            src={Img5}
            alt="PLC Training"
            className="w-full h-auto block rounded-[36px]"
          />

          <div className="p-[30px] bg-[#F2F2F2] rounded-[36px] flex h-full flex-col gap-[12px] justify-start xxl:p-[21px]">
            <h3 className="text-[#1D1D1F] text-[20px] font-bold tracking-[-1px] leading-[24px]">
            {t("marineTit")}
            </h3>
            <div className="grid gap-[15px]">
              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("marine1")}
                </p>
              </div>

              <div className="flex gap-[9px] items-start">
                <div className="block h-[6px] w-[6px] bg-[#D1D1D1] rounded-[99px] mt-[4px]"></div>
                <p className="text-[16px] leading-[16px] tracking-[-0.4px] text-[#3D414E] font-medium">
                {t("marine2")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Industries;
