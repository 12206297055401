import { Link } from "react-router-dom"; 
import Al1 from "../assets/anpc-1.png";
import Al2 from "../assets/anpc-2.png";
import Logo from "../assets/logo-footer.svg";
import { useTranslation } from "react-i18next";

// Import documents for download
import TermsRomanian from "../assets/TermsRomanian.pdf";
import TermsEnglish from "../assets/TermsEnglish.pdf";
import PrivacyPolicy from "../assets/PrivacyPolicy.docx";

function Footer() {
  const { t, i18n } = useTranslation();

  // Function to handle Terms download based on language
  const handleTermsDownload = () => {
    const currentLanguage = i18n.language;

    // Determine the correct terms file based on language
    let termsFile = TermsEnglish; // Default to English terms

    if (currentLanguage === "ro") {
      termsFile = TermsRomanian;
    } 

    // Create a temporary link to download the file
    const link = document.createElement("a");
    link.href = termsFile;
    link.download = currentLanguage === "ro" ? "Termeni-Română.pdf" : "Terms-English.pdf";
    link.click();
  };

  // Function to handle Privacy Policy download
  const handlePrivacyDownload = () => {
    const link = document.createElement("a");
    link.href = PrivacyPolicy;
    link.download = "PrivacyPolicy.docx";
    link.click();
  };

  return (
    <footer className="bg-[#F5F5F7] p-[40px] pt-[0px] xxl:p-[20px] xxl:pt-[0px]">
      <div className="footer-bg p-[50px] pb-[0px] rounded-[30px] xxl:p-[42px] xxl:pb-[0px] xxl:rounded-[24px] xl:rounded-[20px] lg:p-[24px]">
        <div className="flex justify-between w-full items-start xl:grid xl:gap-[60px]">
          <img src={Logo} alt="img" className="max-w-[220px] w-full block xxl:max-w-[200px]"/>
          <div className="flex gap-[150px] items-start xml:gap-[80px] lg:grid lg:gap-[54px]">
            <div className="grid gap-[27px]">
              <Link
                to={"/"}
                className="text-[18px] font-medium underline text-white tracking-[-0.6px] leading-[18px] inline-block w-fit transition-all hover:translate-y-[-6px] cursor-pointer xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px]"
              >
                {t('homePage')}
              </Link>
              <Link
                to={"/about"}
                className="text-[18px] font-medium underline text-white tracking-[-0.6px] leading-[18px] inline-block w-fit transition-all hover:translate-y-[-6px] cursor-pointer xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px]"
              >
                {t('aboutPage')}
              </Link>
              <Link
                to={"/services"}
                className="text-[18px] font-medium underline text-white tracking-[-0.6px] leading-[18px] inline-block w-fit transition-all hover:translate-y-[-6px] cursor-pointer xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px]"
              >
               {t('servicesPage')}
              </Link>
              <Link
                to={"/contact"}
                className="text-[18px] font-medium underline text-white tracking-[-0.6px] leading-[18px] inline-block w-fit transition-all hover:translate-y-[-6px] cursor-pointer xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px]"
              >
                {t('contactPage')}
              </Link>
            </div>

            <div className="grid gap-[27px]">
              <p className="text-[18px] font-normal text-[#BCCED0] tracking-[-0.6px] leading-[18px] inline-block w-fit xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px] ">
                Email: office@kronstadtautomation.ro
              </p>
              <p className="text-[18px] font-normal text-[#BCCED0] tracking-[-0.6px] leading-[18px] inline-block w-fit xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px] ">
              {t('phoneFooter')}
              </p>
              <p className="text-[18px] font-normal text-[#BCCED0] tracking-[-0.6px] leading-[18px] inline-block w-fit max-w-[400px] xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px]">
              {t('addressFooter')}
              </p>
            </div>

            <div className="grid gap-[27px] justify-items-end xl:justify-items-start">
                <span className="text-[18px] font-medium underline text-white text-right tracking-[-0.6px] leading-[18px] inline-block w-fit transition-all hover:translate-y-[-6px] cursor-pointer xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px]">Instagram</span>
                <span className="text-[18px] font-medium underline text-white text-right tracking-[-0.6px] leading-[18px] inline-block w-fit transition-all hover:translate-y-[-6px] cursor-pointer xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px]">Facebook</span>
                <span className="text-[18px] font-medium underline text-white text-right tracking-[-0.6px] leading-[18px] inline-block w-fit transition-all hover:translate-y-[-6px] cursor-pointer xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px]">Twitter</span>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-end items-center pt-[69px] pb-[69px] xl:justify-start xl:pt-[60px] xl:pb-[60px]">
          <div className="flex gap-[20px] md:grid">
            <img src={Al1} alt="img" className="max-w-[240px] w-full block"/>
            <img src={Al2} alt="img" className="max-w-[240px] w-full block"/>
          </div>
        </div>

        <div className="w-full flex h-[93px] justify-between items-center border-t-[1px] border-[#ffffff8f] xl:h-fit xl:pt-[30px] xl:pb-[42px] lg:pb-[0px] xl:grid xl:gap-[24px]">
          <span className="text-white text-[18px] font-normal tracking-[-0.6px] leading-[18px] inline-block w-fit xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px]">
          {t('footerCopyright')}
          </span>
          <div className="flex gap-[20px] sm:grid">
          <button 
            id="terms" 
            onClick={handleTermsDownload} 
            className="text-[18px] font-medium underline text-white tracking-[-0.6px] leading-[18px] inline-block w-fit transition-all hover:translate-y-[-6px] cursor-pointer xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px]"
          >
          {t('terms')}
          </button>
          <button 
            id="privacy" 
            onClick={handlePrivacyDownload} 
            className="text-[18px] font-medium underline text-white tracking-[-0.6px] leading-[18px] inline-block w-fit transition-all hover:translate-y-[-6px] cursor-pointer xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px]"
          >
            {t('privacyPolicy')}
          </button>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
