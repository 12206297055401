import React from "react";
import { useTranslation } from "react-i18next";

const TopContact = () => {
  const { t } = useTranslation();

  return (
    <div className="pt-[140px] px-[40px] xxl:px-[20px] xml:pt-[100px] lg:pt-[80px] pb-[140px] border-b border-1 border-[#D2D3D4] xml:pb-[100px] lg:pb-[80px]">
      <div className="flex max-w-[1370px] w-full mx-auto justify-between items-start gap-[24px] lg:grid lg:justify-center">
        <p className="text-[#1D1D1F] text-[21px] leading-[24px] tracking-[-1px] font-medium max-w-[400px] w-full text-center lg:text-[18px] lg:leading-[21px] lg:tracking-[-0.6px]">
          Email: office@kronstadtautomation.ro
        </p>
        <p className="text-[#1D1D1F] text-[21px] leading-[24px] tracking-[-1px] font-medium max-w-[400px] w-full text-center lg:text-[18px] lg:leading-[21px] lg:tracking-[-0.6px]">
          {t("phoneFooter")}
        </p>
        <p className="text-[#1D1D1F] text-[21px] leading-[24px] tracking-[-1px] font-medium max-w-[400px] w-full text-center lg:text-[18px] lg:leading-[21px] lg:tracking-[-0.6px]">
          {t("addressFooter")}
        </p>
      </div>
    </div>
  );
};

export default TopContact;
