import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import Banner from "../components/Banner";

import image1 from '../assets/bg-1.jpg';  // Import the images
import image2 from '../assets/bg-2.jpg';
import image3 from '../assets/bg-3.jpg';

import { useTranslation } from "react-i18next";
import Training from "../components/Training";
import KeyComponents from "../components/KeyComponents";
import Industries from "../components/Industries";
import Offer from "../components/Offer";



const Home = () => {

  const images = [image1, image2, image3];

  const {t} = useTranslation();

  return (
    <div>
      <Navbar />
      <Banner title="Kronstadt Automation" subtitle={t('subtitleHome')} images={images} />
      <Offer />
      <KeyComponents />
      <Industries />
      <Training />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Home;


