import React, { useEffect, useRef } from "react";
import Img1 from "../assets/img-t-1.svg";
import Img2 from "../assets/img-t-2.svg";
import Img3 from "../assets/img-t-3.svg";
import Img4 from "../assets/img-t-4.svg";
import Img5 from "../assets/img-t-5.svg";
import Img6 from "../assets/img-t-6.svg";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register GSAP ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

function KeyComponents() {
  const { t } = useTranslation();

  // Refs for h1, span, and white boxes
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const boxRefs = useRef([]);

  useEffect(() => {
    // Animate the h1 (title) and span (subtitle)
    gsap.fromTo(
      titleRef.current,
      { opacity: 0, y: 50 }, // Initial state (invisible and moved down)
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top 80%", // Trigger when 80% of the element is visible
          end: "bottom 10%", // End when it's 10% from the bottom
          toggleActions: "play none none reverse", // Reverse the animation when scrolling up
        },
      }
    );

    gsap.fromTo(
      subtitleRef.current,
      { opacity: 0, y: 50 }, // Initial state (invisible and moved down)
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: subtitleRef.current,
          start: "top 80%", // Trigger when 80% of the element is visible
          end: "bottom 10%", // End when it's 10% from the bottom
          toggleActions: "play none none reverse", // Reverse the animation when scrolling up
        },
      }
    );

    // Animate each white box (bg-white)
    gsap.utils.toArray(boxRefs.current).forEach((box, index) => {
      gsap.fromTo(
        box,
        { opacity: 0, y: 50 }, // Initial state (invisible and moved down)
        {
          opacity: 1,
          y: 0,
          duration: 1.2,
          ease: "power3.out",
          scrollTrigger: {
            trigger: box,
            start: "top 80%", // Trigger when 80% of the box is in view
            end: "bottom 10%", // End when it's 10% from the bottom
            toggleActions: "play none none reverse", // Reverse when scrolling back up
          },
        }
      );
    });
  }, []);

  return (
    <section className="pt-[140px] px-[40px] xxl:px-[20px] xml:pt-[100px] lg:pt-[80px] pb-[140px] border-b border-1 border-[#D2D3D4] xml:pb-[100px] lg:pb-[80px]">
      <div className="flex gap-[148px] items-start max-w-[2000px] w-full mx-auto pb-[80px] xxl:pl-[20px] xxl:gap-[100px] lg:grid lg:pl-0 lg:gap-[24px] lg:pb-[60px]">
        {/* Training Header */}
        <div className="flex gap-[15px] items-center mt-[22px] xml:mt-[12px] lg:mt-0">
          <div className="w-[13px] h-[13px] block rounded-[99px] bg-[#CE4800]"></div>
          <span
            ref={subtitleRef} // Attach ref for animation
            className="uppercase text-[18px] font-semibold leading-[21px] tracking-[0px] text-[#CE4800] lg:mt-0"
          >
            {t("keyComponentsSub")}
          </span>
        </div>
        <h1
          ref={titleRef} // Attach ref for animation
          className="text-[#1D1D1F] font-light text-[80px] tracking-[-2.7px] leading-[66px] max-w-[600px] xxl:text-[66px] xxl:tracking-[-2.4px] xxl:leading-[63px] xml:text-[51px] xml:leading-[48px] xml:tracking-[-1.2px] md:text-[36px] md:tracking-[-1px] md:leading-[39px]"
        >
          {t("keyComponentsTit")}
        </h1>
      </div>

      <div className="grid grid-flow-row w-full gap-[16px] grid-cols-3 xl:grid-cols-2 md:grid-cols-1">
        {/* White Boxes */}
        <div
          ref={(el) => (boxRefs.current[0] = el)} // Add refs to white boxes
          className="bg-white p-[69px] rounded-[30px] shadow-xl xxl:p-[42px] md:p-[30px]"
        >
          <span className="text-[#CE4800] font-medium block tracking-[-2.4px] text-[60px] leading-[60px] text-right w-full xl:text-[54px]">
            01
          </span>
          <img
            src={Img1}
            alt="PLC Training"
            className="max-w-[260px] w-full h-auto block xml:max-w-[200px]"
          />

          <div className="grid gap-[30px] pt-[60px] lg:pt-[39px] lg:gap-[18px]">
            <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
              {t("programLogicTit")}
            </h3>
            <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium">
              {t("programLogicPar")}
            </p>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[1] = el)} // Add refs to white boxes
          className="bg-white p-[69px] rounded-[30px] shadow-xl xxl:p-[42px] md:p-[30px]"
        >
          <span className="text-[#CE4800] font-medium block tracking-[-2.4px] text-[60px] leading-[60px] text-right w-full xl:text-[54px]">
            02
          </span>
          <img
            src={Img2}
            alt="PLC Training"
            className="max-w-[260px] w-full h-auto block xml:max-w-[200px]"
          />

          <div className="grid gap-[30px] pt-[60px] lg:pt-[39px] lg:gap-[18px]">
            <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
              {t("distributedTit")}
            </h3>
            <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium">
              {t("distributedPar")}
            </p>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[2] = el)} // Add refs to white boxes
          className="bg-white p-[69px] rounded-[30px] shadow-xl xxl:p-[42px] md:p-[30px]"
        >
          <span className="text-[#CE4800] font-medium block tracking-[-2.4px] text-[60px] leading-[60px] text-right w-full xl:text-[54px]">
            03
          </span>
          <img
            src={Img3}
            alt="PLC Training"
            className="max-w-[260px] w-full h-auto block xml:max-w-[200px]"
          />

          <div className="grid gap-[30px] pt-[60px] lg:pt-[39px] lg:gap-[18px]">
            <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
              {t("humanTit")}
            </h3>
            <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium">
              {t("humanPar")}
            </p>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[3] = el)} // Add refs to white boxes
          className="bg-white p-[69px] rounded-[30px] shadow-xl xxl:p-[42px] md:p-[30px]"
        >
          <span className="text-[#CE4800] font-medium block tracking-[-2.4px] text-[60px] leading-[60px] text-right w-full xl:text-[54px]">
            04
          </span>
          <img
            src={Img4}
            alt="PLC Training"
            className="max-w-[260px] w-full h-auto block xml:max-w-[200px]"
          />

          <div className="grid gap-[30px] pt-[60px] lg:pt-[39px] lg:gap-[18px]">
            <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
              {t("supervisoryTit")}
            </h3>
            <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium">
              {t("supervisoryPar")}
            </p>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[4] = el)} // Add refs to white boxes
          className="bg-white p-[69px] rounded-[30px] shadow-xl xxl:p-[42px] md:p-[30px]"
        >
          <span className="text-[#CE4800] font-medium block tracking-[-2.4px] text-[60px] leading-[60px] text-right w-full xl:text-[54px]">
            05
          </span>
          <img
            src={Img5}
            alt="PLC Training"
            className="max-w-[260px] w-full h-auto block xml:max-w-[200px]"
          />

          <div className="grid gap-[30px] pt-[60px] lg:pt-[39px] lg:gap-[18px]">
            <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
              {t("instrumentationTit")}
            </h3>
            <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium">
              {t("instrumentationPar")}
            </p>
          </div>
        </div>

        <div
          ref={(el) => (boxRefs.current[5] = el)} // Add refs to white boxes
          className="bg-white p-[69px] rounded-[30px] shadow-xl xxl:p-[42px] md:p-[30px]"
        >
          <span className="text-[#CE4800] font-medium block tracking-[-2.4px] text-[60px] leading-[60px] text-right w-full xl:text-[54px]">
            06
          </span>
          <img
            src={Img6}
            alt="PLC Training"
            className="max-w-[260px] w-full h-auto block xml:max-w-[200px]"
          />

          <div className="grid gap-[30px] pt-[60px] lg:pt-[39px] lg:gap-[18px]">
            <h3 className="text-[#1D1D1F] text-[24px] font-bold tracking-[-1px] leading-[21px] xxl:text-[21px]">
              {t("virtualizationTit")}
            </h3>
            <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium">
              {t("virtualizationPar")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default KeyComponents;