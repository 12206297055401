// import { useTranslation } from "react-i18next";
// react router
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Services from "./pages/Services";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Error from "./pages/Error";
import Home from "./pages/Home";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/*",
    element: <Error />,
  },
]);


function App() {
  
  return (
      <div className="App">
         <RouterProvider router={router} />
      </div>
  );
}

export default App;
