import React, { useEffect, useRef } from "react";
import image1 from "../assets/img-main.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";

// Register GSAP ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const PicturePart = () => {
  const { t } = useTranslation();

  // Refs for title and subtitle
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);

  useEffect(() => {
    // Animate the title (h1)
    gsap.fromTo(
      titleRef.current,
      { opacity: 0, y: 50 }, // Initial state (invisible and moved down)
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top 80%", // Trigger when 80% of the element is visible
          end: "bottom 10%", // End when it's 10% from the bottom
          toggleActions: "play none none reverse", // Reverse the animation when scrolling up
        },
      }
    );

    // Animate the subtitle (span)
    gsap.fromTo(
      subtitleRef.current,
      { opacity: 0, y: 50 }, // Initial state (invisible and moved down)
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: subtitleRef.current,
          start: "top 80%", // Trigger when 80% of the element is visible
          end: "bottom 10%", // End when it's 10% from the bottom
          toggleActions: "play none none reverse", // Reverse the animation when scrolling up
        },
      }
    );

    return () => {
      // Cleanup ScrollTrigger instances when the component unmounts
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []); // Empty dependency array to ensure this effect runs only once when the component mounts

  return (
    <section className="pt-[140px] px-[40px] xxl:px-[20px] xml:pt-[100px] lg:pt-[80px] pb-[140px] border-b border-1 border-[#D2D3D4] xml:pb-[100px] lg:pb-[80px] max-w-[2000px] w-full mx-auto">
      <div>
        <p
          className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium md:text-[16px] md:leading-[21px] md:tracking-[-0.4px] text-center max-w-[800px] mx-auto w-full sm:text-[16px] sm:leading-[21px] sm:tracking-[-0.4px]"
          ref={subtitleRef}
        >
          {t("servicesPagePar")}
        </p>

        <p
          className="text-[#1D1D1F] text-[21px] leading-[27px] tracking-[-1.2px] pt-[42px] lg:pt-[30px] max-w-[420px] w-full mx-auto font-bold pb-[12px] text-center md:pb-[30px]"
          ref={titleRef}
        >
          {t("servicesPageTit")}
        </p>
      </div>

      <img
        src={image1}
        alt="PLC Training"
        className="max-w-[1500px] w-full h-auto block mx-auto"
      />
    </section>
  );
};

export default PicturePart;
