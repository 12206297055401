import React, { useEffect, useRef } from "react";
import scrollImg from "../assets/scrollimg.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";

const Offer = () => {
  const { t } = useTranslation();
  // Register the ScrollTrigger plugin with GSAP
  gsap.registerPlugin(ScrollTrigger);

  // Refs for the h1, span, and the three white boxes
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const paragraphRef = useRef(null);

  // GSAP scroll animations for the title and subtitle
  useEffect(() => {
    gsap.fromTo(
      titleRef.current,
      { opacity: 0, y: 50 }, // Start with opacity 0 and 50px below
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top 80%", // Trigger when 80% of the title is in view
          end: "bottom 10%", // End when the title is 10% from the bottom of the viewport
          toggleActions: "play none none reverse", // Reverse when scrolling back up
        },
      }
    );

    gsap.fromTo(
      subtitleRef.current,
      { opacity: 0, y: 50 }, // Start with opacity 0 and 50px below
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: subtitleRef.current,
          start: "top 80%", // Trigger when subtitle is 80% in view
          end: "bottom 10%", // End when subtitle is 10% from the bottom
          toggleActions: "play none none reverse", // Reverse when scrolling up
        },
      }
    );

    gsap.fromTo(
      paragraphRef.current,
      { opacity: 0, y: 50 }, // Start with opacity 0 and 50px below
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: paragraphRef.current,  // Make sure to trigger paragraph
          start: "top 80%", // Trigger when paragraph is 80% in view
          end: "bottom 10%", // End when paragraph is 10% from the bottom
          toggleActions: "play none none reverse", // Reverse when scrolling up
        },
      }
    );
  }, []);

  return (
    <section className="pt-[140px] px-[40px] xxl:px-[20px] xml:pt-[100px] lg:pt-[80px] pb-[140px] border-b border-1 border-[#D2D3D4] xml:pb-[100px] lg:pb-[80px] relative">
      <div className="flex gap-[148px] items-start max-w-[2000px] w-full mx-auto xxl:pl-[20px] xxl:gap-[100px] lg:grid lg:pl-0 lg:gap-[24px]">
        {/* Training Header */}
        <div className="flex gap-[15px] items-center mt-[22px] xml:mt-[12px] lg:mt-0">
          <div className="w-[13px] h-[13px] block rounded-[99px] bg-[#CE4800]"></div>
          <span ref={subtitleRef} className="uppercase text-[18px] font-semibold leading-[21px] tracking-[0px] text-[#CE4800] lg:mt-0">
          {t("weOfferSpan")}
          </span>
        </div>
        <div className="grid gap-[60px] xxl:gap-[42px] xl:gap-[36px] md:gap-[30px]">
          <h1 className="text-[#1D1D1F] font-light text-[80px] tracking-[-2.7px] leading-[66px] max-w-[600px] xxl:text-[66px] xxl:tracking-[-2.4px] xxl:leading-[63px] xml:text-[51px] xml:leading-[48px] xml:tracking-[-1.2px] md:text-[36px] md:tracking-[-1px] md:leading-[39px]" ref={titleRef}>
          {t("weOfferTit")}
          </h1>

          <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium max-w-[800px] w-full lg:text-[16px] lg:leading-[21px] lg:tracking-[-0.4px]" ref={paragraphRef}>
          {t("weOfferPar")}
          </p>
        </div>
      </div>

      {/* Scroll Down Icon */}
      <div className="absolute bottom-[140px] right-[140px] justify-items-center xml:hidden grid gap-[12px]">
        <img src={scrollImg} alt="img" />
        <span className="text-[#1D1D1F] font-bold leading-[14px] text-[16px] tracking-tight">
        {t("seeMore")}
        </span>
      </div>
    </section>
  );
};

export default Offer;