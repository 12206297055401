import React, { useState, useEffect } from "react";
import LanguageSelector from "./LanguageSelector"; // Import the LanguageSelector component
import { Link, useLocation } from "react-router-dom"; // Import useLocation to get the current path
import { useTranslation } from "react-i18next";
import Logo from "../assets/logo-nav.svg";
import HamburgerMenu from "./HamburgerMenu";

const Navbar = () => {
  const handleLinkClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  // State to track if the page has scrolled
  const [scrolled, setScrolled] = useState(false);

  const { t } = useTranslation();
  const location = useLocation(); // Get the current path

  // Effect to monitor scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true); // Change background to dark if scrolled more than 50px
      } else {
        setScrolled(false); // Revert back to white background if less than 50px
      }
    };

    // Add event listener for scroll
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`fixed top-0 left-0 w-full transition-all duration-300 ease-in-out ${
        scrolled ? "bg-white" : "bg-transparent"
      } ${scrolled ? "shadow-nav" : "shadow-none"} text-white z-50`}
    >
      <div className="w-full mx-auto px-[40px] xxl:px-[20px] py-[12px] h-fit grid items-center xl:px-[12px]">
        <div className="flex items-center justify-between">
          {/* Logo or Brand Name */}
          <div
            className={`text-2xl font-semibold ${
              scrolled ? "text-white" : "text-black"
            }`}
          >
            <img
              src={Logo}
              alt="img"
              className={`${
                scrolled ? "logo-color-change" : "filter-none"
              } xl:w-[100px]`}
            />
          </div>

          {/* Links and Language Selector */}
          <div className="flex items-center space-x-6">
            <div className="flex gap-[33px] border-[#F5F5F7] border-2 shadow-sm bg-white h-[60px] px-[42px] rounded-[99px] items-center xl:hidden">
              {/* Home Link */}
              <Link
                to="/"
                onClick={handleLinkClick}
                className={`text-[16px] font-medium text-[#1D1D1F] tracking-[-0.4px] leading-[18px] inline-block w-fit transition-all hover:text-[#CE4800] cursor-pointer xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px] relative ${
                  location.pathname === "/" ? "underline" : ""
                }`}
              >
                {t("homePage")}
                {location.pathname === "/" && (
                  <div className="underline absolute w-full bottom-[-23px] rounded-[99px] left-0 h-[4px] bg-[#CE4800]"></div>
                )}
              </Link>

              {/* About Link */}
              <Link
                to="/about"
                onClick={handleLinkClick}
                className={`text-[16px] font-medium text-[#1D1D1F] tracking-[-0.4px] leading-[18px] inline-block w-fit transition-all hover:text-[#CE4800] cursor-pointer xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px] relative ${
                  location.pathname === "/about" ? "underline" : ""
                }`}
              >
                {t("aboutPage")}
                {location.pathname === "/about" && (
                  <div className="underline absolute w-full bottom-[-23px] rounded-[99px] left-0 h-[4px] bg-[#CE4800]"></div>
                )}
              </Link>

              {/* Services Link */}
              <Link
                to="/services"
                onClick={handleLinkClick}
                className={`text-[16px] font-medium text-[#1D1D1F] tracking-[-0.4px] leading-[18px] inline-block w-fit transition-all hover:text-[#CE4800] cursor-pointer xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px] relative ${
                  location.pathname === "/services" ? "underline" : ""
                }`}
              >
                {t("servicesPage")}
                {location.pathname === "/services" && (
                  <div className="underline absolute w-full bottom-[-23px] rounded-[99px] left-0 h-[4px] bg-[#CE4800]"></div>
                )}
              </Link>

              {/* Contact Link */}
              <Link
                to="/contact"
                onClick={handleLinkClick}
                className={`text-[16px] font-medium text-[#1D1D1F] tracking-[-0.4px] leading-[18px] inline-block w-fit transition-all hover:text-[#CE4800] cursor-pointer xxl:leading-[16px] xxl:tracking-[-0.4px] xxl:text-[16px] relative ${
                  location.pathname === "/contact" ? "underline" : ""
                }`}
              >
                {t("contactPage")}
                {location.pathname === "/contact" && (
                  <div className="underline absolute w-full bottom-[-23px] rounded-[99px] left-0 h-[4px] bg-[#CE4800]"></div>
                )}
              </Link>
            </div>
          </div>

          <div className="flex gap-[16px] items-center">
            {/* Language Selector & Hamburger Menu */}
            <LanguageSelector />
            <HamburgerMenu />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
