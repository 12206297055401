import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register ScrollTrigger plugin with GSAP
gsap.registerPlugin(ScrollTrigger);

const Banner = ({ title, subtitle, images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Refs for title and subtitle to animate
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);

  // Change image every 5 seconds (for carousel effect)
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [images.length]);

  // GSAP scroll animations for title and subtitle
  useEffect(() => {
    gsap.fromTo(
      titleRef.current,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top 80%", // Trigger when top of element hits 80% of viewport height
          end: "bottom 10%", // End when bottom of element is 10% from the viewport
          toggleActions: "play none none reverse", // Reverse when scrolling up
        },
      }
    );

    gsap.fromTo(
      subtitleRef.current,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: subtitleRef.current,
          start: "top 80%",
          end: "bottom 10%",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  return (
    <div
      className="relative w-full min-h-[777px] bg-center flex justify-center items-center opacity-0 animate-fadeIn bg-cover"
      style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
    >
      <div className="text-center text-white px-[40px] lg:px-[20px] py-6 z-10">
        <h1
          ref={titleRef}
          className="text-white font-light text-[80px] tracking-[-1px] leading-[66px] max-w-[809px] mx-auto xxl:text-[66px] xxl:tracking-[-1px] xxl:leading-[63px] xml:text-[51px] xml:leading-[48px] xml:tracking-[-1px] text-center title md:text-[36px] md:tracking-[-1px] md:leading-[39px]"
        >
          {title}
        </h1>
        <p
          ref={subtitleRef}
          className="mx-auto max-w-[660px] w-full text-white font-medium text-[18px] tracking-[-0.4px] leading-[27px] mt-[24px] xl:text-[16px] xl:leading-[24px] subtitle"
        >
          {subtitle}
        </p>
      </div>
    </div>
  );
};

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Banner;