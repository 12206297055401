import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import Banner from "../components/Banner";

import image1 from '../assets/bg-1.jpg';  // Import the images
import image2 from '../assets/bg-2.jpg';
import image3 from '../assets/bg-3.jpg';

import { useTranslation } from "react-i18next";
import TopContact from "../components/TopContact";
import ContactForm from "../components/ContactForm";


const Contact = () => {

  const images = [image1, image2, image3];

  const {t} = useTranslation();

  return (
    <div>
      <Navbar />
      <Banner title={t('contactPageTit')} subtitle={t('contactPageSub')} images={images} />
      <TopContact />
      <ContactForm />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Contact;